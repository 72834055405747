// extracted by mini-css-extract-plugin
export var rive = "Button-module--rive--3dXey";
export var stretch = "Button-module--stretch--cjDuc";
export var primary = "Button-module--primary--1Znlo";
export var changeColor = "Button-module--changeColor--3lE7W";
export var secondary = "Button-module--secondary--2lup3";
export var tertiary = "Button-module--tertiary--1vQAb";
export var large = "Button-module--large--1KHrG";
export var normal = "Button-module--normal--3gWQx";
export var small = "Button-module--small--1d711";
export var rotate = "Button-module--rotate--3iylt";