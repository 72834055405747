import * as React from "react";

import { Link } from "gatsby"

import Button from "../Button/Button";

import * as styles from './Page404.module.scss';

const Page404 = () => {

    return (
        <div className={styles.Page404}>
            <h1>404</h1>
            <p>The requested page<br/> can't be found</p>
            <Link
                to="/"
            >
                <Button
                    kind="primary"
                    icon="arrow_right"
                >
                    Back to home
                </Button>
            </Link>
        </div>
    );
};

export default Page404;
