import * as React from "react"

import * as styles from './Button.module.scss';
import arrow_right from "../../images/arrow-right.svg";
import arrow_up from "../../images/arrow-up.svg";
import maximize from "../../images/maximize.svg";
import minimize from "../../images/minimize.svg";

import { useEffect, useState } from 'react';
import { useRive, useStateMachineInput } from "@rive-app/react-canvas";

import Rive, { Layout, Fit, Alignment } from '@rive-app/react-canvas';
import RiveComponent from '@rive-app/react-canvas';


import RiveContent from "../RiveContent/RiveContent"

const Button = (props) => {
    
    const [hovered, setHovered] = useState(false);
    
    return (
        <button 
            className={`${(props.kind === undefined) ? styles.primary : kind_to_style(props.kind)} 
            ${layout_to_style(props.layout)} 
            ${size_to_style(props.size)}`}
            onClick={props.onClick}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        > 
            {left_icon(props.leftIcon, hovered)}
            <span>{props.children}</span>
            {right_icon(props.rightIcon, hovered)}
        </button>
    );
}

const kind_to_style = (kind) => {
    if (kind === "primary") {
        return styles.primary;
    } else if (kind === "secondary") {
        return styles.secondary; 
    } else if (kind === "tertiary") {
        return styles.tertiary;
    }
}

const size_to_style = (size) => {
    if (size === undefined || size === "normal") {
        return styles.normal;
    } else if (size === "large") {
        return styles.large;
    } else if (size === "small") {
        return styles.small;
    }
}

const layout_to_style = (layout) => {
    if (layout === undefined || layout === "hug") {
        return null;
    } else if (layout === "stretch") {
        return styles.stretch;
    } else if (layout === "fixed") {
        return styles.fixed;
    }
}

const right_icon = (rightIcon, hover) => {
    if (rightIcon === undefined) {
        return null;
    } else if (rightIcon === "contact") {
        return <RiveContent className={styles.rive} artboard={"contact"} playing={hover}/>;
    } else if (rightIcon === "arrow") {
        return <RiveContent className={styles.rive} artboard={"arrow"} playing={hover}/>;
    }
}

const left_icon = (leftIcon, hover) => {
    if (leftIcon === undefined) {
        return null;
    } else if (leftIcon === "contact") {
        return <RiveContent className={styles.rive} artboard={"contact"} playing={hover}/>;
    } else if (leftIcon === "arrow") {
        return <RiveContent className={styles.rive} artboard={"arrow"} playing={hover}/>;
    }
}

export default Button;