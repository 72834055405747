import * as React from "react"
import Page404 from "../components/Page404/Page404";

const NotFoundPage = () => {

	const path = [
		{url: "/", name: "Home"},
		{url: null, name: "404"},
	];

	return (
		<Page404/>
	)
}

export default NotFoundPage
 