import * as React from "react";
import { useEffect } from 'react';
import { useRive, useStateMachineInput } from "@rive-app/react-canvas";
import Rive, { Layout, Fit, Alignment } from '@rive-app/react-canvas';
import RiveComponent from '@rive-app/react-canvas';

import * as styles from './RiveContent.module.scss';

const RiveContent = ({stateMachines = "states", artboard, width, height = "auto", autoplay = true, playing = false, clicked = false}) => {

    const { rive, RiveComponent } = useRive({
        src: "./portfolio.riv",
        stateMachines: stateMachines,
        artboard: artboard,
        autoplay: autoplay,
        layout: new Layout({
          fit: Fit.Contain,
          alignment: Alignment.TopCenter,
        }),
        onStateChange: (event) => {
          console.log(event.data[0]);
        }
    });
    
    const isHoveredInput = useStateMachineInput(rive, stateMachines, "isHovered");
    
    const isClickedInput = useStateMachineInput(rive, stateMachines, "isClicked");
    
    useEffect(() => {
        playing && isHoveredInput.fire()
    }, [playing])
    
    useEffect(() => {
        console.log(isClickedInput);
        if (isClickedInput) {
            isClickedInput.value = clicked;
          }
    }, [clicked, isClickedInput])

    return (
        <div
        className={styles.rive}
        style={{
        width : `${width}rem`, 
        height: height === "auto" ? "auto" : `${height}rem`}}
        >
            <RiveComponent/>
        </div>
    );
};

export default RiveContent;